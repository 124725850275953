import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="flex justify-center items-center h-screen">
      <div>
        <h1 className="font-light py-4">404 | Not Found</h1>
        {/* <p className="text-xs text-center">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p> */}
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
